<div class="dispatch-detail">
  <div class="main-content dispatch-dynamic-height">
    <dispatch-route-header></dispatch-route-header>
    <div class="route-tab">
      <nz-tabset [nzSelectedIndex]="tabSelected">
        <nz-tab nzTitle="Route">
          <dispatch-route-detail></dispatch-route-detail>
        </nz-tab>
      </nz-tabset>
    </div>
  </div>
</div>