import { Component, Input } from "@angular/core";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { BaseComponent } from "@abstract/BaseComponent";
import { ActivatedRoute } from "@angular/router";
import { Job } from "@wearewarp/types/data-model";
@Component({
  selector: '[job-item]',
  templateUrl: './index.html',
  styleUrls: ['index.scss'],
})
export class JobItem extends BaseComponent {
  private _job: Job;
  @Input() get job() { return this._job }
  @Input() isSelectedItem: boolean = false;
  set job(value) {
    this._job = value;
    this.processJobItem(value);
  }
  public displayInfo;
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }
  
  ngOnInit(): void {
    super.ngOnInit();
  }

  private processJobItem(job) {
    let displayInfo: any = {};
    displayInfo.label = this.getJobLabel(job);
    displayInfo.carrierName = this.getCarrierName(job.carrier);
    let driverName = this.getDriverName(job.driver);
    displayInfo.driverName = driverName ? `${driverName} (Driver)`:'N/A';
    displayInfo.startLocation = this.getLocation(job?.metadata?.firstPickInfo);
    displayInfo.endLocation = this.getLocation(job?.metadata?.lastDropInfo);
    displayInfo.codeJob = job.code;
    displayInfo.displayPick = this.displayTimeWindowForJob(job.pickDt) ?? 'N/A';
    displayInfo.displayDrop = this.displayTimeWindowForJob(job.dropDt) ?? 'N/A';
    displayInfo.countStops = this.countStops(job);
    displayInfo.pickWindow = this.displayWindow(Object.assign({ time: job.earliest_time, timeTo: job.latest_time }, job.pickDt));
    displayInfo.dropWindow = this.displayWindow(job.dropDt);
    this.displayInfo = displayInfo;
  }

  getJobLabel(job) {
    let str = job.clients?.[0]?.name;
    if (job.clients?.length > 1) {
      str += ` (+${job.clients.length - 1})`;
    }
    return str;
  }

  private getLocation(task) {
    if (!task) return null;
    const address = task.address || task.addr;
    return `${address?.city}, ${address?.state}`;
  }

  countStops(job) {
    return job.stops?.length ? `(${job.stops.length} Stops)` : '';
  }

  private displayTimeWindowForJob(data) {
    let formatDateTime = "MM/DD/YYYY HH:mm";
    let shortTimezone = DateUtil.timezoneStandardToUsShort(data.timezone);
    const from = data?.time;
    if (!from) return;
    let time = DateUtil.displayLocalTime(from, {
      timezone: data.timezone,
      format: formatDateTime,
    });
    let date = time.split(' ')[0];
    let hour = time.split(' ')[1];
    return {
      date: date,
      hour: hour,
      timezone: shortTimezone
    }
  }

  private displayWindow(obj: { time: string, timeTo: string, timezone: string, isAppointment: boolean }) {
    if (!obj) return 'N/A';
    let formatDateTime = Const.FORMAT_GUI_DATETIME_V3;
    let timeFrom = obj.time;
    let timeTo = obj.timeTo;
    timeFrom = DateUtil.displayLocalTime(timeFrom, { timezone: obj.timezone, format: formatDateTime });
    timeTo = DateUtil.displayLocalTime(timeTo, { timezone: obj.timezone, format: formatDateTime });
    let arr1 = timeFrom.split(',');
    let arr2 = timeTo.split(',');
    let isSameDay = arr1[0] == arr2[0];
    if (isSameDay) {
      timeTo = arr2[1]?.trim();
    }
    let str = `${timeFrom || 'N/A'} - ${timeTo || 'N/A'}`;
    let tzShort = DateUtil.timezoneStandardToUsShort(obj.timezone);
    if (tzShort) {
      str += ` ${tzShort}`;
    }
    return str;
  }
  
  public getCarrierName(carrier): string {
    let name = carrier?.basicInfo?.name ?? '';
    if (name) name += ' (Carrier)';
    return name;
  }
}