import { Injectable } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { Utils } from "@services/utils";
import { Job, ShipmentItem } from "@wearewarp/types/data-model";
import { AttachedFile } from '@wearewarp/types/data-model';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import StopEntity from "./Entity/StopEntity";
import ShipmentEntity from "./Entity/ShipmentEntity";
import TaskEntity from "./Entity/TaskEntity";
import { AttachedFileUtil } from "@services/attached-file-util";
import { takeUntil } from "rxjs/operators";


export interface JobPaginationInfo {
  count: number,
  limit: number,
  skip: number,
  total: number
}
export interface TabData {
  need_pod: number,
  need_confirm: number,
  has_issue: number,
}
export interface PODLocalFile extends AttachedFile {
  localUrl?: string,
}

var CACHE_LOCAL_PODS: Map<string, any> = new Map();

@Injectable({
  providedIn: 'root'
})
export class PodService {
  private jobs: Map<string, Job> = new Map();
  private jobPaginationData: JobPaginationInfo;
  private selectedJob: Job;
  private selectedStop: StopEntity;
  private stopEntities: StopEntity[];
  private presentPod;
  private message: Subject<{ type: string, message: string }> = new Subject()
  public onSelectedJobChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public onSelectedPodChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public listJobs: BehaviorSubject<any> = new BehaviorSubject(null);
  private podArray = [];
  private shipmentItemsMap: Map<string, ShipmentItem> = new Map();
  private shipmentEntities: Map<string, ShipmentEntity> = new Map();
  private taskEntities: Map<string, TaskEntity> = new Map();
  private type: string = '';
  private shouldSelectedThisPodAfterDeleted;
  public loadDataTime = 0;
  public loading : BehaviorSubject<boolean> = new BehaviorSubject(true);
  private tabData;
  public notifyCancelReq = new Subject<boolean>();
  constructor(private api: ApiService) { }
  public async fetchJobSelected() {
    let promises = [
      this.fetchStops(),
      this.fetchTasks(),
      this.fetchShipments(),
      this.fetchShipmentItems()
    ];
    await Promise.all(promises).catch(e => {
      console.log(e);
    })
  }

  public async fetchJobs(query?: any) {
    try {
      this.loadDataTime +=1;
      let url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.type}`);
      if (Utils.isObjectNotEmpty(query) && query) {
        let qs = new URLSearchParams(query).toString();
        url += '?' + qs;
      }
      const resp = await this.api.GET(url).toPromise();
      const data = resp?.data;
      this.jobs = new Map(data?.list_data.map(job => [job.id, job]));
      delete data.list_data;
      this.tabData = data?.summary;
      console.log(this.tabData)
      delete data.summary;
      this.jobPaginationData = data;
      let d = {
        jobs: Array.from(this.jobs.values()),
        tabData: this.tabData
      }
      this.listJobs.next(d);
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchStops() {
    try {
      let url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.selectedJob.id}/pods`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data.list_data;
      this.stopEntities = await Promise.all(data.map(async (stop, index) => (await new StopEntity(this).setIndex(index).init(stop))));
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchTasks(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/tasks`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const taskEntity = new TaskEntity(this).init(item);
        this.taskEntities.set(taskEntity.getId(), taskEntity);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchShipments(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/shipments`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        const shipmentEntity = new ShipmentEntity(this).init(item);
        this.shipmentEntities.set(shipmentEntity.getId(), shipmentEntity);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchShipmentItems(): Promise<void> {
    try {
      let url = Const.APIV2(`${Const.APIURI_JOBS}/${this.selectedJob.id}/shipment_items`);
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data?.list_data;
      for (let item of data) {
        this.shipmentItemsMap.set(item.id, item);
      }
    }
    catch (e) {
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async fetchRoute(jobId: string) {
    try {
      let url = `${Const.APIURI_JOBS_V2}/${jobId}`;
      const resp = await this.api.GET(url).toPromise();
      const data = resp.data;
      this.selectedJob = data;
    }
    catch (e) {
      console.log(e)
      this.message.next({
        type: 'error',
        message: e.message
      })
    }
  }

  public async getJobsMore(params) {
    try {
      let url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.type}`);
      let qs = new URLSearchParams(params).toString();
      url += '?' + qs;
      const resp = await this.api.GET(url).toPromise();
      const data = resp?.data?.list_data;
      if (data && Utils.isArrayNotEmpty(data))
        data.forEach(job => { this.jobs.set(job.id, job) });
      const cloneResp = resp?.data;
      delete cloneResp.list_data;
      this.jobPaginationData = cloneResp;
      let d = {
        jobs: Array.from(this.jobs.values()),
        getMore: 1
      }
      this.listJobs.next(d);
    } catch (e) {
    }
  }

  public getJobPaginationData() {
    return this.jobPaginationData;
  }

  public setJobPaginationData(obj: JobPaginationInfo) {
    this.jobPaginationData = obj;
  }

  public getJobs() {
    return Array.from(this.jobs.values());
  }

  public getStops() {
    return this.stopEntities;
  }

  public getSelectedJob() {
    return this.selectedJob;
  }

  public async setSelectedJob(jobId: string, stopId?: string, taskId?: string, podId?: string) {
    if (this.selectedJob?.id === jobId && !stopId) return;
    // nếu có stopId thì hiểu là có pod thay đổi nên cần phải fetch lại route trong db
    this.loading.next(true);
    if (!stopId) {
      await this.clearCache();
      this.selectedJob = this.jobs.get(jobId);
      if (!this.selectedJob) {
        await this.fetchRoute(jobId);
      }
    } else {
      if (this.podArray.length <= 1) this.shouldSelectedThisPodAfterDeleted = null;
      else {
        let index;
        if (this.presentPod?.taskId) {
          for (let i = 0; i < this.podArray.length; i++)
            if (this.podArray[i]?.taskId && this.podArray[i]?.taskId === this.presentPod?.taskId && this.podArray[i]?._id === this.presentPod._id) {
              index = i;
              break;
            }
        } else {
          for (let i = 0; i < this.podArray.length; i++)
            if (!this.podArray[i]?.taskId && this.podArray[i]?._id === this.presentPod._id) {
              index = i;
              break;
            }
        }
        if (index === 0) this.shouldSelectedThisPodAfterDeleted = this.podArray[1];
        else this.shouldSelectedThisPodAfterDeleted = this.podArray[index - 1]
      }
      await this.fetchRoute(jobId);
      let jobsArr = this.getJobs();
      let index = 0;
      for (let i = 0; i < jobsArr.length; i++) {
        if (jobsArr[i].id === this.selectedJob.id) {
          index = i;
          break;
        }
      }
      switch (this.type) {
        case 'need_pod': {
          if (!this.selectedJob?.isNeedPOD) {
            stopId = null;
            this.jobs.delete(this.selectedJob.id);
            if (index == jobsArr.length - 1)
              this.selectedJob = jobsArr[index - 1];
            else this.selectedJob = jobsArr[index + 1];
          }
          break;
        };
        case 'need_confirm': {
          if (!this.selectedJob?.isNeedConfirm) {
            stopId = null;
            this.jobs.delete(this.selectedJob.id);
            if (index == jobsArr.length - 1)
              this.selectedJob = jobsArr[index - 1];
            else this.selectedJob = jobsArr[index + 1];
          }
          break;
        };
        case 'has_issue': {
          if (!this.selectedJob?.isHasIssue) {
            stopId = null;
            this.jobs.delete(this.selectedJob.id);
            if (index == jobsArr.length - 1)
              this.selectedJob = jobsArr[index - 1];
            else this.selectedJob = jobsArr[index + 1];
          }
          break;
        }
      }
    }
    await this.fetchJobSelected();
    this.podArray = [];
    for (let stop of Array.from(this.stopEntities.values())) {
      let pods = this.filterPodForStop(stop.getPods(), stop.toJSON().taskIds);
      this.podArray = [...this.podArray, ...pods]
    }

    this.podArray = this.processPods(this.podArray);

    const data = { stopId, taskId, podId, selectedJob: this.selectedJob };
    this.onSelectedJobChanged.next(data);
  }

  private processPods(podArray) {
    return podArray.map((item, index) => {
      return {
        ...item,
        index,
        localUrl: () => this.attachedFileUrl(item),
        isPdf: AttachedFileUtil.isPdf(item),
        createdAt: item?.insert.when,
        podConfirmed: item?.podConfirmed || {},
      };
    });
  }

  public getSelectedStop() {
    return this.selectedStop;
  }

  public async setSelectedStop(stopId: string, podId?: any, taskId?: string) {
    this.selectedStop = this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
    if (!this.selectedStop.getPods().length) {
      // this.presentPod = null;
      this.onSelectedPodChanged.next(null);
      return;
    }
    if (this.podArray?.length) {
      if (podId && taskId) {
        let arr = this.podArray.filter(it => (it?.taskId && it?.taskId === taskId && it?._id === podId));
        if (arr.length) {
          this.presentPod = arr[0];
        } else {
          this.presentPod = this.shouldSelectedThisPodAfterDeleted;
        }
      }
      else if (taskId) this.presentPod = this.podArray.filter(it => (it?.taskId && it?.taskId === taskId))[0];
      else if (podId) {
        let arr = this.podArray.filter(it => (!it?.taskId && it?._id === podId));
        if (arr.length) {
          this.presentPod = arr[0];
        } else {
          this.presentPod = this.shouldSelectedThisPodAfterDeleted;
        }
      }
      else this.presentPod = this.podArray.filter(it => (it.stopId === stopId))[0];
      await this.presentPod.localUrl();
      this.onSelectedPodChanged.next(this.presentPod);
      // this.loading.next(false)
    } else {
      this.presentPod = null;
      this.onSelectedPodChanged.next(null);
    }
  }

  public getStopById(stopId) {
    return this.stopEntities.filter(stop => (stop.getId() === stopId))[0];
  }

  public setPresentPod(index: number) {
    if (index >= this.podArray.length || index < 0) return;
    this.presentPod = this.podArray[index];
    this.onSelectedPodChanged.next(this.presentPod);
  }

  public handleChangePod(type: string) {
    if (this.podArray.length === 0) return;
    let index = this.podArray.indexOf(this.presentPod);
    switch (type) {
      case 'next': {
        this.setPresentPod(index + 1); break;
      }

      case 'back':
        this.setPresentPod(index - 1); break;
      default: break;
    }
  }

  public async onClickToChangePod(podId: string, stopId: string, taskId?: any) {
    if (taskId) {
      this.presentPod = this.podArray.filter(it => (it?.taskId && it.taskId === taskId && it._id === podId && it?.stopId === stopId))[0];
    } else {
      this.presentPod = this.podArray.filter(it => (!it?.taskId && it._id === podId && it?.stopId === stopId))[0];
    }
    this.onSelectedPodChanged.next(this.presentPod)


  }


  public getPodByStop(stopId: string){
    return this.podArray.filter(pod => pod.stopId == stopId)
  }

  public getShipmentById(shipmentId: string) {
    return this.shipmentEntities.get(shipmentId);
  }

  public getShipmentItemById(itemId: string) {
    return this.shipmentItemsMap.get(itemId);
  }

  public getTaskById(taskId: string) {
    return this.taskEntities.get(taskId);
  }

  public getTaskEntities() {
    return Array.from(this.taskEntities.values());
  }

  public getType() {
    return this.type;
  }

  public setType(type: string) {
    this.type = type;
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  public filterPodForStop(pods: any[], taskIds: string[]) {
    let podAllTasks = pods.filter(it => !it?.taskId);
    let podEachTask = pods.filter(it => (it?.taskId));
    let flag = 0;
    for (let taskId of taskIds) {
      let i: any = {};
      i.pods = podEachTask.filter(it => {
        return it.taskId === taskId;
      }) || [];
      if (i.pods.length !== podAllTasks.length) {
        flag = 1;
        break;
      }
    }
    if (!flag) return podAllTasks;
    else return pods;
  }

  public async attachedFileUrl(pod: PODLocalFile, ...otherParams) {
    const cached = CACHE_LOCAL_PODS.get(pod._id);
    if(cached) pod.localUrl = cached;
    if (pod.localUrl) return pod.localUrl;
    const podUrl = AttachedFileUtil.attachedFileUrl(pod, true);
    const response = await this.api.GET(`${podUrl}?no-redirect=1`).pipe(takeUntil(this.notifyCancelReq)).toPromise();
    if(response){
      pod.localUrl = response?.data?.url;
      if(AttachedFileUtil.isPdf(pod)){
        const file = new Blob([response?.data?.url], { type: pod.type });
        const fileURL = URL.createObjectURL(file);
        CACHE_LOCAL_PODS.set(pod._id, fileURL);
      }
    }
    return pod.localUrl;
  }

  private async clearCache(){
    const caches = Array.from(CACHE_LOCAL_PODS.values());
    caches.map(url => URL.revokeObjectURL(url));
    CACHE_LOCAL_PODS.clear()
  }
  public getTabData(): TabData{
    return this.tabData;
  }
}
