import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Shipment } from "@wearewarp/types/data-model";

@Component({
    selector: '[detail-transit-network]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
  })
export class DetailTransitNetwork extends BaseComponent{
    @Input() podId;
    public shipments : Shipment[];
    public isLoading = true;
    public prevTaskIds = [];
    constructor(){
        super();
    }
    ngOnChanges(): void {
        if(this.podId ){
            this.isLoading = true;
            const url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.podId}/shipments`);
            this.api.GET(url).subscribe(resp => {
                this.shipments = resp.data.list_data;
                console.log(this.shipments)
                this.isLoading = false;
            })
        }
    }
}