<div *ngIf="!isLoading && podId">
    <div class="label">Those shipments have the present POD</div>
    <div class="shipment-tag">
        <nz-tag *ngFor="let item of displayShipmentIds" nzMode="closeable" [nzColor]="'green'" (nzOnClose)="onClose(item)">{{getShipmentWarpId(item)}}</nz-tag>
    </div>
    <div class="footer">
        <button nz-button nzType="primary" (click)="onDiscard()">Discard</button>
        <button nz-button nzType="primary" (click)="onSave()">Save</button>
    </div>
</div>
<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<nz-empty *ngIf="!podId && !isLoading"></nz-empty>