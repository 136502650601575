import { BaseComponent } from "@abstract/BaseComponent";
import { Component, HostListener } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PodService } from "../../pod.service";
import { getDashboard } from "@services/index";
import { Const } from "@const/Const";

@Component({
  selector: 'sub-window',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class SubWindow extends BaseComponent {
  public carrierId = '';
  public jobId;
  public stopId;
  public podId;
  public taskIds;
  public origin: string;
  public bc;
  constructor(public activatedRoute: ActivatedRoute, private podService: PodService) {
    super();
    this.origin = window.location.origin;
    this.bc = new BroadcastChannel(Const.BroadcastChanelPodConfirmation);
    this.bc.postMessage('inited')
    this.bc.onmessage = (event) => {
      if (event.origin !== this.origin) return;
      if (event.data?.from !== 'warp-web') return;
      let queryParams: any = {}
      if(event.data?.jobId) queryParams.jobId = event.data?.jobId;
      if(event.data?.stopId) queryParams.stopId = event.data?.stopId;
      if(event.data?.podId) queryParams.podId = event.data?.podId;
      if(event.data?.carrierId) queryParams.carrierId = event.data?.carrierId;
      this.routeWithQueryUrl(queryParams,true);
    }
    const bc1 =new BroadcastChannel(Const.BroadcastChanelPodConfirmation);
    
  }
  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.jobId = this.queryParams?.jobId;
    this.stopId = this.queryParams?.stopId;
    this.podId = this.queryParams?.podId;
    this.carrierId = this.queryParams?.carrierId;
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.jobId = queryParams?.jobId;
      this.stopId = queryParams?.stopId;
      this.podId = queryParams?.podId;
      this.carrierId = queryParams?.carrierId;
    })
  }
}
