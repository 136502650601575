import { Component } from "@angular/core";
import { PodService } from "../../pod.service";
import StopEntity from "../../Entity/StopEntity";
import { StringULID } from "@wearewarp/types";
import { BaseComponent } from "@abstract/BaseComponent";

@Component({
  selector: 'stop-list',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})
export class StopListComponent extends BaseComponent{
  public isLoading = false;
  public expanded?: boolean = true;
  public showShipments = false;
  public selectedStopId: StringULID = null;
  public selectedJobId = null;
  public listData: StopEntity[] = [];
  constructor(private podService: PodService) {
    super();
    this.subscription.add( this.podService.loading.subscribe(data => {
      this.isLoading = data;
     }))
    this.subscription.add(this.podService.onSelectedJobChanged.subscribe(data => {
      if(data){
        let selectedJobIdData = data.selectedJob?.id;
      if(this.selectedJobId !=  selectedJobIdData || data?.stopId){
        this.selectedJobId = selectedJobIdData;
        this.listData = this.podService.getStops();
        if(data?.stopId){
          this.setSeletedStop(data.stopId,1, data?.taskId, data?.podId)
        }else{
          const defaultSelectedStop = this.listData.find(it => (it.getPods()?.length));
          if(defaultSelectedStop){
            this.setSeletedStop(defaultSelectedStop.getId());
          }else{
            this.setSeletedStop(this.listData[0]?.getId())
          }
        }
      }
      this.isLoading = false;
      }
    }))
    this.subscription.add(this.podService.onSelectedPodChanged.subscribe(data => {
      if(!data ){
        if(!this.podService.getSelectedStop()) return;
        let stop = this.podService.getSelectedStop();
        this.selectedStopId = stop.getId();
        const selectedItem : HTMLElement = document.getElementById(`stop-${this.selectedStopId}`);
        this.scrollToElement(selectedItem, { behavior: 'smooth' });
      }else{
        if(this.selectedStopId === data.stopId) return;
        this.selectedStopId = data.stopId;
        const selectedItem : HTMLElement = document.getElementById(`stop-${this.selectedStopId}`);
        this.scrollToElement(selectedItem, { behavior: 'smooth' });
      }
    }))
  }

  public setSeletedStop(stopId: string,pass?: number, taskId?: string, podId?: string) {
    if(pass){
      this.selectedStopId = stopId;
      if(taskId && podId){
        this.podService.setSelectedStop(stopId, podId, taskId);
        return;
      }else if(podId){
        this.podService.setSelectedStop(stopId, podId); 
        return
      }else if(taskId){
        this.podService.setSelectedStop(stopId,null,taskId);
        return
      }else{
        this.podService.setSelectedStop(stopId);
        return;
      }
    }
    if(stopId === this.selectedStopId) return;
    this.selectedStopId = stopId;
    this.podService.setSelectedStop(stopId);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
