import { Component } from "@angular/core";
import { PodService } from "../../pod.service";
import { BaseList } from "@app/admin/base/list";
import { Const } from "@const/Const";
import { ActivatedRoute } from "@angular/router";
import { Job } from "@wearewarp/types/data-model";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";
import { JobPaginationInfo } from "../../pod.service";
@Component({
  selector: 'job-list',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class JobListComponent {
  public isScrollTop = false;
  public isFirstLoadDetail = true;
  public selectedItem: any = false;
  public paginationInfo: JobPaginationInfo;
  public isLoading = true;
  public listData: Job[] = [];
  constructor(
    public activatedRoute: ActivatedRoute,
    public podService: PodService,
  ) {
    this.podService.onSelectedJobChanged.subscribe(data => {
      if(data){
        if(data?.selectedJob?.id !== this.selectedItem) this.listData = this.podService.getJobs();
        this.selectedItem = data.selectedJob?.id;
      }
    })
    this.podService.listJobs.subscribe(data => {
      if(data){
        this.listData = data.jobs;
        this.paginationInfo = this.podService.getJobPaginationData();
        this.isLoading = false;
      }
      
    })
  }

  public get routeAdminPODNeedConfirm() {
    return Const.routeAdminPODNeedConfirm;
  }

  public get currentQuerryParams() {
    return this.activatedRoute?.snapshot?.queryParams;
  }

  public queryParams(jobId) {
    let currentQuerryparams = this.activatedRoute?.snapshot?.queryParams;
    let newQuerryParams = { ...currentQuerryparams };
    newQuerryParams.jobId = jobId;
    return newQuerryParams;
  }

  public getDataMore() {
    if (this.isLoading) return
    if (this.listData?.length >= this.paginationInfo?.total) return false;
    let params: any = {};
    params.skip = this.listData.length;
    params.limit = this.paginationInfo.limit;
    let filter: any = {}
    if (this.currentQuerryParams.sort) {
      params.sort = this.currentQuerryParams.sort;
    }
    if (this.currentQuerryParams.search) {
      filter.search = this.currentQuerryParams.search;
    }
    if (this.currentQuerryParams.filter) {
      let f = this.currentQuerryParams.filter;
      for(let key of Object.keys(f))
      filter[key] = f[key];
    }
    if(Utils.isObjectNotEmpty(filter))
      params.filter = JSON.stringify(filter);
    this.isLoading = true;
    this.podService.getJobsMore(params);
  }

  setSelectedItem(id){
    this.selectedItem = id;
    this.podService.loading.next(true)
    this.podService.notifyCancelReq.next(true)
  }
}
