<div class="screen-container">
    <div class="sub-container-1">
        <div style="display: flex; flex-direction: column; width:300px;">
            <div class="note">
                <h2 style="margin-top: 5px; margin-left: 5px;">POD Issue</h2>
                <div notes-component [subjectId]="podId" [subjectType]="'pod'" [allowDeletion]="true"
                    [allowDeleteItemNotMine]="true" [hasUploadFile]="true"></div>
            </div>
            <div class="carrier-info">
                <h2 style="margin-top: 5px; margin-left: 5px;">Carrier Information</h2>
                <div *ngIf="carrierId" carrier-info [carrierId]="carrierId"></div>
                <nz-empty *ngIf="!carrierId"></nz-empty>
            </div>
        </div>
        <div class="transit-network">
            <h2 style="margin-top: 5px; margin-left: 5px;">Transit Network</h2>
            <div *ngIf="podId" detail-transit-network [podId]="podId" class="detail-transit-network"></div>
            <nz-empty *ngIf="!podId"></nz-empty>
        </div>
    </div>
    <div class="sub-container-2">
        <dispatch-stops [routeId]="jobId"></dispatch-stops>
    </div>
</div>