<div class="list-container">
  <div class="list-content">
    <ng-container *ngIf="!isLoading && !listData?.length">
      <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
    </ng-container>

    <ng-container *ngIf="isLoading">
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <ng-container *ngFor="let item of listData;">
        <div class="list-item" id="list-stop-pop-confirm" (click)="setSeletedStop(item.getId())">
          <div style="padding: 5px;" [ngClass]="{ 'selected-stop-pod-confirm': selectedStopId === item.getId() }" id="stop-{{item.getId()}}">
            <stop-item [stop]="item"></stop-item>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
