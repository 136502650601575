
<div class="carrier-basic-info" >
    <div *ngIf="!isLoading">
        <div class="info">
            <span class="info-label">Name:</span> {{carrier?.basicInfo?.name || 'N/A'}}
        </div>
        <div class="info">
            <span class="info-label">MC:</span> {{carrier?.basicInfo?.mc || 'N/A'}}
        </div>
        <div class="info">
            <span class="info-label">DOT:</span> {{carrier?.basicInfo?.dot || 'N/A'}}
        </div>
        <div class="info">
            <span class="info-label">Phone:</span> {{carrier?.basicInfo?.owner?.phone || 'N/A'}}
        </div>
        <div class="info">
            <span class="info-label">Email:</span> {{carrier?.basicInfo?.owner?.email || 'N/A'}}
        </div>
    </div>
    <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
</div>
