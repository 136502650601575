import { Component } from "@angular/core";
import { getDashboard } from "@services/index";
import { BaseComponent } from "@abstract/BaseComponent";
import { NeedConfirmationComponent } from "./components/need-confirmation";
import { PodService, TabData } from "./pod.service";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";

@Component({
  selector: 'pod-confirmation',
  templateUrl: './index.html',
  styleUrls: [
    './style.scss',
    '../list.scss'
  ]
})
export class PodConfirmationIndex extends BaseComponent {
  public tabIndex = -1;
  public presentSearch = null;
  public subWindow = null;
  public noPod = true;
  public presentPod;
  public url : string;
  public bc;
  public needSentFirstMessage = true;
  public tabs = [
    {
      key: 'need_pod',
      title: 'Need POD',
      total: 0,
      component: NeedConfirmationComponent
    },
    {
      key: 'need_confirm',
      title: 'Need Confirm',
      total: 0,
      component: NeedConfirmationComponent
    },
    {
      key: 'has_issue',
      title: 'Has Issue',
      total: 0,
      component: NeedConfirmationComponent
    }
  ];
  constructor(private podService: PodService, public activatedRoute: ActivatedRoute){
    super();
    window.addEventListener('beforeunload', () => {
      if(this.subWindow) this.subWindow.close();
    })
    this.url = window.location.origin + `/dashboard/finance-v2/pod-confirmation/sub-window`;
    this.bc = new BroadcastChannel(Const.BroadcastChanelPodConfirmation);
    this.bc.onmessage = (event) => {
      if(event.data !== 'inited' || !this.needSentFirstMessage) return; //chỉ bắn message nếu bật mới subwindow của mình còn khi tab khác bật lên thì ko gửi message nữa
      this.needSentFirstMessage = false
      if(!this.noPod){
      let presentPod = this.presentPod;
      let carrierId = this.podService.getSelectedJob()?.carrierId;
      let podId = presentPod._id;
      let taskIds = presentPod?.taskId ? [presentPod.taskId]: presentPod.taskIds;
      let jobId = this.podService.getSelectedJob()?.id;
      let stopId = this.podService.getSelectedStop()?.getId();
      this.bc.postMessage({from:'warp-web',carrierId,podId, jobId, stopId, taskIds})
    }else{
      let carrierId = this.podService.getSelectedJob()?.carrierId;
      let jobId = this.podService.getSelectedJob()?.id;
      this.bc.postMessage({from:'warp-web',carrierId, jobId});
    }
    }
  }
  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const {tab} = this.queryParams;
    if(tab){
      if(this.podService.getType() != tab){
        this.podService.setType(tab);
        if(this.queryParams?.search){
          let q:any = {};
          let filter: any = {};
          filter['search'] = this.queryParams.search;
          q.filter = JSON.stringify(filter);
          this.podService.fetchJobs(q);
        }else {
          this.podService.fetchJobs();
        }
      }
      if(this.queryParams?.search !== this.presentSearch){
        // lần đầu load thì sẽ ko gọi fetchjob vì bên trên đã fetch rồi
        if(this.presentSearch === null){
          this.presentSearch = this.queryParams?.search;
          return;
        }
        this.presentSearch = this.queryParams?.search;
        if(this.queryParams?.search){
          let q:any = {};
          let filter: any = {};
          filter['search'] = this.queryParams.search;
          q.filter = JSON.stringify(filter);
          this.podService.fetchJobs(q);
        }else {
          this.podService.fetchJobs();
        }
      }
    }
    if (!tab || !this.isTabValid(tab)) {
      return this.routeWithQueryUrl({tab: this.tabs[0].key});
    }
    let index = this.tabKeyToIndex(tab);
    if (this.tabIndex != index) {
      this.tabIndex = index;
    }
  }

  private isTabValid(key: string): boolean {
    return this.tabs.filter(it => it.key == key).length == 1;
  }

  private tabKeyToIndex(key: string): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].key == key) {
        return i;
      }
    }
    return 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
    if(this.subWindow) this.subWindow.close();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.subscription.add(this.podService.listJobs.subscribe(data => {
      if(data?.tabData){
        let tabData: TabData = this.podService.getTabData();
        for(let tab of this.tabs){
          tab.total = tabData[tab.key];
        }
      }
      if(!data?.getMore && data){
        let jobs = data.jobs;
        let selectedJobId = this.activatedRoute?.snapshot?.queryParams?.jobId;
        let search = this.activatedRoute?.snapshot?.queryParams?.search;
        if(search){
          let hasJob = false;
          for(let sum of Object.values(data?.tabData)){
            if(sum) hasJob = true;
          }
          if(!hasJob){
            this.modalService.confirm({
              nzTitle:'No data',
              nzContent: `Sorry, this job doesn't exist in this feature, but you can find it in Dispatch`,
              nzOkText: 'Go to Dispatch',
              nzCancelText: 'Cancel',
              nzStyle: {
                top: '250px'
              },
              nzOnOk: () => {
                if(this.podService.loadDataTime === 1){
                  window.open(`${Const.routeAdminDispatchList}/${selectedJobId}`,'_blank')
                }
                else window.open(`${Const.routeAdminDispatchList}/?search=${search}`,'_blank')
              }
            })
          }
        }
        if(selectedJobId && (jobs.filter(job => (job.id === selectedJobId)).length || this.podService.loadDataTime === 1)){
          this.podService.setSelectedJob(selectedJobId);       
        }else{
          if(!jobs.length) return;
          this.podService.setSelectedJob(jobs[0].id);
          let currentQuerryparams = this.activatedRoute?.snapshot?.queryParams;
          let newQuerryParams = {
            ...currentQuerryparams,
            jobId:jobs[0].id
          }
          this.routeWithQueryUrl(newQuerryParams);
        }
      }
    }))
    this.subscription.add(this.podService.onSelectedPodChanged.subscribe(data => {
      if(data){
        this.noPod = false;
        this.presentPod = data;
        let presentPod = data;
        // if(this.subWindow){
          let carrierId = this.podService.getSelectedJob()?.carrierId;
          let podId = presentPod._id;
          let taskIds = presentPod?.taskId ? [presentPod.taskId]: presentPod.taskIds;
          let jobId = this.podService.getSelectedJob()?.id;
          let stopId = this.podService.getSelectedStop()?.getId();
          this.bc.postMessage({from:'warp-web',carrierId,podId, jobId, stopId, taskIds});
        // }
      }else{
        this.noPod = true;
        this.presentPod = null;
      //  if(this.subWindow){
         let carrierId = this.podService.getSelectedJob()?.carrierId;
         let jobId = this.podService.getSelectedJob()?.id;
         this.bc.postMessage({from:'warp-web',carrierId, jobId});
       //}
      }
    }))

    this.subscription.add(this.activatedRoute.queryParams.subscribe(queryParams =>{ 
      if(queryParams?.jobId) this.podService.setSelectedJob(queryParams.jobId);
    }))
  }

  public openSubWindow(){
    if(!this.subWindow || this.subWindow.closed){
      this.subWindow = window.open(this.url, "mozillaWindow", "popup");
      this.needSentFirstMessage = true;
    }
  }

  ngOnDestroy(): void {
    if(this.subWindow){
      this.subWindow.close();
    }
    this.subscription.unsubscribe();
  }
}
