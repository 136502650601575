<div class="screen-container">
  <div class="screen-filter">
    <pod-confirm-filter></pod-confirm-filter>
  </div>
  <div class="screen-content">
    <div class="job-container">
      <job-list></job-list>
    </div>
    <div class="stop-container">
      <stop-list></stop-list>
    </div>
    <div class="item-container"  id="pod-modal-item" *ngIf="!noPod">
      <div ngxViewer  [viewerOptions]="imageViewOption" style="width: 100%;" id="pod-type-image" *ngIf="!isPdf(presentPod)"></div>
      <div style="width: 100%; position: relative;"  *ngIf="isPdf(presentPod) && fileUrl && !this.isLoadingPdfPOD">
        <pdf-viewer (after-load-complete)="loadedPdfPod($event)" [fit-to-page]="1" [zoom-scale]="'page-height'" [src]="fileUrl" style="height: 100%" ></pdf-viewer>
      </div>
      <div *ngIf="isLoading || isLoadingPdfPOD" style="width: 100%; height: 100%; position: absolute; background-color: #fff;; z-index: 2;">
        <div  class="nodata" style=" margin-top: 100px;"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </div>
      <div class="buttons">
        <div class="btn-navigation back" [ngClass]="{'black-button': isPdf(presentPod), 'white-button':!isPdf(presentPod)}" (click)="onBtnBack()">
          <i nz-icon nzType="left" nzTheme="outline"></i>
        </div>
        <div class="space"></div>
        <div  class="btn-navigation next" [ngClass]="{'black-button': isPdf(presentPod), 'white-button':!isPdf(presentPod)}" (click)="onBtnNext()">
          <i nz-icon nzType="right" nzTheme="outline"></i>
        </div>
      </div>
    </div>
    <div class="item-container" *ngIf="noPod">
      <div *ngIf="isLoading" style="width: 100%; height: 100%; position: absolute; background-color: #fff;; z-index: 2;">
        <div  class="nodata" style=" margin-top: 100px;"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </div>
        <nz-result nzStatus="404" nzTitle="No POD" nzSubTitle="This Stop hasn't had any pod. Upload to see.">
        </nz-result>
    </div>
    <div class="item-info">
      <pod-info ></pod-info>
    </div>
  </div>
</div>
