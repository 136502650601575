import { Component, Input } from "@angular/core";
import RouteEntity from "../../../../dispatch/entity/RouteEntity";
import { DispatchService } from "../../../../dispatch/dispatchService";
import { Subscription } from "rxjs";
import { DialogService } from "@dialogs/dialog.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { DriverLocationService } from "../../../../dispatch/dispatch-live-tracking/driver-location";
/**
 * Component độc lập để có thể sử dụng ở bất kỳ đâu.
 * <dispatch-detail [routeId]="xxx"></dispatch-detail>
 */
@Component({
  selector: 'dispatch-stops',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss',
  ]
})
export class DispatchStops {
  constructor(
    private dispatchService: DispatchService,
    private driverLocationService: DriverLocationService,
    protected notification: NzNotificationService
  ) {
    let params = new URLSearchParams(window.location.search);
    const redirect = params.get("redirect");
    this.tabSelected = this.tabIndexs[redirect] || 0;
  }

  private _routeId: string;
  public tabSelected: number = 0;
  private tabIndexs = {
    'route': 0,
    'carrier-driver': 1,
    'sales': 2,
    'documents': 3,
    'customers': 4
  }

  @Input() set routeId(routeId: string) {
    this._routeId = routeId;
    if (!routeId) return;
    this.driverLocationService.setRouteId(routeId);
    this.dispatchService.setRouteId(routeId).refresh()
  }
  get routeId() {
    return this._routeId;
  }
  private subscription: Subscription = new Subscription();
  protected route: RouteEntity;

  ngOnInit(): void {
    this.subscription.add(this.dispatchService.message.subscribe((data) => {
      this.notification[data.type]?.(data.message)
    }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
