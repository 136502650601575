import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { FormInputCostModule } from '../base/form-input-cost/module';
import { PodConfirmationIndex } from '.';
import { SharedModule } from '../shared/shared.module';
import { UiCommonModule } from '../components/common/module';
import { JobListComponent } from './components/job-list';
import { NeedConfirmationComponent } from './components/need-confirmation';
import { StopListComponent } from './components/stop-list';
import { PodService } from './pod.service';
import { PODInfo } from './components/pod-info';
import { DrawerModule } from '@app/drawers/drawer.module';
import { DispatchModule } from '../dispatch/module';
import { CustomIconModule } from '../components/icon/icon.module';
import { PODList } from './components/pod-list';
import { StopItem } from './components/stop-item';
import { NgxViewerModule } from 'ngx-viewer';
import { PodConfirmationRoutingModule } from './pod-confirmation.route';
import { PdfViewerModule } from '@libs/pdf-viewer/pdf-viewer.module';
import { JobItem } from './components/job-list-item';
import { CreateByUser } from './components/create-by-user';
import { ShipmentStructureModule } from '../shipments/shipment-structure/module';
import { CarrierInfo } from './components/carrier-info';
import { SubWindow } from './components/sub-window';
import { CheckShipmentForPod } from './components/sub-window/check-shipment-for-pod';
import { DetailTransitNetwork } from './components/sub-window/detail-transit-network';
import { FinanceModule } from '../finance-v2/module';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { PodConfirmationFilter } from './components/filter';
import { DispatchStops } from './components/sub-window/dispatch-stops';
import { ShipmentWarpidComponent } from './components/shipment-warpid/shipment-warpid';
import { ModuleNotes } from '../components/notes/module';
import { ImageLazy } from './components/img-lazy';
import { SafeUrlPipe } from './pipes/safeUrl.pipe';
import { ListJobItemModule } from '../dispatch/components/list-job-item/module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PodConfirmationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzTabsModule,
    NzEmptyModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    SearchBoxModule,
    SelectBySearchingModule,
    FormInputCostModule,
    NzToolTipModule,
    NzTypographyModule,
    NzPopoverModule,
    SharedModule,
    UiCommonModule,
    NzBadgeModule,
    DrawerModule,
    CustomIconModule,
    NgxViewerModule,
    DispatchModule,
    PdfViewerModule,
    ShipmentStructureModule,
    FinanceModule,
    ModuleNotes,
    ListJobItemModule
  ],
  declarations: [
    PodConfirmationIndex,
    NeedConfirmationComponent,
    JobListComponent,
    StopListComponent,
    PODInfo,
    PODList,
    StopItem,
    JobItem,
    CreateByUser,
    CarrierInfo,
    SubWindow,
    CheckShipmentForPod,
    DetailTransitNetwork,
    PodConfirmationFilter,
    DispatchStops,
    ImageLazy,
    ShipmentWarpidComponent,
    SafeUrlPipe
  ],
  exports: [
    PodConfirmationIndex,
  ],
  providers: [
    PodService
  ]
})
export class PodConfirmationModule { }
