import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import * as pdfjsLib from "pdfjs-dist";
import { AttachedFile } from "@wearewarp/types/data-model";
import { AttachedFileUtil } from "@services/attached-file-util";
import { BaseComponent } from "@abstract/BaseComponent";
import { PODLocalFile, PodService } from "../../pod.service";
import TaskEntity from "../../Entity/TaskEntity";
import { ImageUtil } from "@services/image-util";
import { ActivatedRoute } from "@angular/router";
pdfjsLib.GlobalWorkerOptions.workerSrc = "assets/mozilla/pdfjs-2.13.216/pdf.worker.min.js";
@Component({
  selector: 'pod-list',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class PODList extends BaseComponent{
  @Input() set pods(values) {
    if(!values) return;
    this.items = values.filter(it => (it?.taskId));
    this.podAllTasks = values.filter(it => !it?.taskId);
    this.preparePodUrlForTask();
  }
  @Input() tasks : TaskEntity[] = [];
  @Input() stopId;
  public items: any = [];
  public isLoading: boolean = false;
  public selectedTask;
  public selected: any = false;
  public displayInfo = [];
  public podAllTasks = [];
  public isUploading : Map<string, boolean> = new Map();
  public shouldShowEachTaskPod: boolean;

  constructor(private podService: PodService, public activatedRoute: ActivatedRoute) {
    super();
    this.subscription.add(this.podService.onSelectedPodChanged.subscribe(data => {
      if(data){
        let pod = data;
        this.selected = pod?._id;
        this.selectedTask = pod?.taskId || null;
      }else{
        this.selected = false;
        this.selectedTask = null;
      }
    }))
  }

  ngOnChanges(): void {
    let flag = 0
    for(let task of this.tasks){
      let i: any = {};
      i.id = task.getId();
      let confirmed = 0
      i.warpId = task.getShipment()?.getWarpId();
      i.pods = this.items.filter(it => {
        if(this.isConfirmPOD(it) && it.taskId === task.getId()) confirmed +=1;
        return it.taskId === task.getId();
      }) || [];
      if(task.getPodUploadFiles().length !== this.podAllTasks.length) flag = 1;
      i.confirmed = confirmed;
      this.displayInfo.push(i);
    }
    if(flag) this.shouldShowEachTaskPod = true;
    else this.shouldShowEachTaskPod = false;

  }

  private async preparePodUrlForTask() {
    for (let i = 0; i < this.podAllTasks.length; i++) {
      let podItem = this.podAllTasks[i];
      if(!this.isPdf(podItem)) continue;
      this.loadPdfPod(podItem, i, true);
    }
   
    for (let i = 0; i < this.items.length; i++) {
      let podItem = this.items[i];
      if(!this.isPdf(podItem)) continue;
      this.loadPdfPod(podItem, i, false)
    }
  }
  public async loadPdfPod(podItem,index ,isPodAllTask? : boolean){
    let url = await podItem.localUrl();
    podItem.loadingTask = pdfjsLib.getDocument({url, withCredentials: true});
    podItem.loadingTask.promise.then(
        (pdf) => {
        pdf.getPage(1).then( (page) => {
          var desiredWidth = 30; // css class attached-pod
          var viewport = page.getViewport({ scale: 1 });
          var scale = desiredWidth / viewport.width;
          var scaledViewport = page.getViewport({ scale: scale });
          var canvas;
          if(!isPodAllTask)
            canvas = <HTMLCanvasElement>(
              document.getElementById(`task-${podItem.taskId}-pod-${index}`)
            );
          else 
            canvas = <HTMLCanvasElement>(
              document.getElementById(`all-tasks-pod-${this.stopId}-${index}`)
            );
          if (!canvas) return;
          var context = canvas.getContext("2d");
          canvas.height = scaledViewport.height;
          canvas.width = scaledViewport.width;
          var renderContext = {
            canvasContext: context,
            viewport: scaledViewport,
          };
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then()
            .catch((e) => console.error("DPF render error ", e));
        });
      },
      function (err) {
        console.error("PDF loading error ", err);
      }
    );
  }
  public async getPodUrl(podItem: PODLocalFile): Promise<string> {
   // console.log("pod", podItem)
    //tạm thời không dùng presigned cho PDF để tránh lỗi CORS khi preview
    if(podItem.localUrl) return podItem.localUrl
    return await this.podService.attachedFileUrl(podItem, !this.isPdf(podItem));
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.by && item.podConfirmed?.when) return true;
    return false;
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  handleClick($event: any, podId: string, stopId: string, taskId?:string){
    if(podId === this.selected && taskId === this.selectedTask) return;
    this.podService.onClickToChangePod(podId, stopId, taskId);
    $event.stopPropagation();
  }

  onClickAddPod(event, taskId?:string){
    let element: HTMLElement;
    if(taskId) element = document.getElementById(`uploadRoutePODInput-${taskId}`) as HTMLElement;
    else element = document.getElementById(`uploadRoutePODInputAllTask${this.stopId}`) as HTMLElement;
    element.click();
    event.stopPropagation();
  }
 
  public async refreshPodChange(){
    await this.podService.setSelectedJob(this.podService.getSelectedJob()?.id,this.stopId,this.selectedTask, this.selected);
    let querryparams = {...this.activatedRoute?.snapshot?.queryParams}
    querryparams.jobId = this.podService.getSelectedJob()?.id;
    this.routeWithQueryUrl(querryparams);
  }

  public getIndexForPod(taskId, podId){
    for(let i = 0; i < this.items.length; i++){
      if(this.items[i].taskId === taskId && this.items[i]._id === podId) return i;
    }
  }

  onFileSelected(inputElement: HTMLInputElement, taskId?: string) {
    let file = inputElement.files[0];
    inputElement.value = "";
    ImageUtil.resizeImageIfNeeded(file).then((blob) =>{
      if(taskId)
        this.uploadPOD(blob, file.name, [taskId]);
      else this.uploadPOD(blob, file.name, this.tasks.map(task => task.getId()), true);
    }
    );
  }

  private async uploadPOD(file: Blob, fileName: string, taskIds: string[], all?: boolean) {
    if(all) this.isUploading['all'] = true;
    else this.isUploading[taskIds[0]] = true;
    let apiUrl = `${Const.APIV2(Const.APIURI_TASKS)}/uploadPodForListTasksOfStop`;
    let formData = new FormData();
    const jsonData = {
      'taskIds': taskIds,
    }
    formData.append("params", JSON.stringify(jsonData));
    formData.append("uploadPOD", file, fileName);
    this.api.postFormData(apiUrl, formData).subscribe(
      (resp) => {
        if(all) this.isUploading['all'] = false;
        else this.isUploading[taskIds[0]] = false;
        this.refreshPodChange()
        this.showSuccess("Your POD has been added successfully.", {nzPlacement: 'bottomRight'});
      },
      (err) => {
        if(all) this.isUploading['all'] = false;
        else this.isUploading[taskIds[0]] = false;
        this.showErr(err, {nzPlacement: 'bottomRight'});
      }
    );
  }
  
  public getColorForShipment(task){
    let podNumber = task.pods.length;
    if(podNumber === 0) return 'red';
    if(podNumber > 0 && task.confirmed < podNumber) return '#d4b106';
    return 'green';
  }

  public isSelected(item, task){
    return item._id === this.selected && this.selectedTask === task.id;
  }

  public isAllTaskPodSelected(item){
    return item._id === this.selected && !this.selectedTask;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
