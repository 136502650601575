import { BaseComponent } from "@abstract/BaseComponent";
import { Component, ElementRef, HostListener, Input, ViewChild } from "@angular/core";
import { NgxViewerDirective } from "ngx-viewer";
import { PODLocalFile, PodService } from "../../pod.service";
import { Utils } from "@services/utils";
import { ActivatedRoute } from "@angular/router";
import { AttachedFile } from "@wearewarp/types/data-model/types/AttachedFile";
import { AttachedFileUtil } from "@services/attached-file-util";
import { Const } from "@const/Const";
import { PDFDocumentProxy } from "pdfjs-dist";
@Component({
  selector: 'need-confirmation',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class NeedConfirmationComponent extends BaseComponent {
  @ViewChild(NgxViewerDirective) ngxViewerElement: NgxViewerDirective;
  @HostListener('document:keyup',['$event'])
  handleKeyboardEvent(event: KeyboardEvent){
    if (event.key === 'ArrowLeft') {
      this.onBtnBack();
    } else if (event.key === 'ArrowRight') {
      this.onBtnNext();
    }
  }

  public presentPODIndex = 0;
  public presentJobId;
  public presentPod: any  =null;
  public arrivedPod: any = null;
  public noPod = true;
  public isLoading = true;
  public isLoadingPdfPOD;
  public fileUrl: string = null;
  public imageViewOption: any = {
    backdrop:true,
    navbar: false ,
    inline: true,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      rotateLeft: 4,
      rotateRight: 4,
      flipHorizontal: 4,
      flipVertical: 4,
    },
  }

  constructor(public activatedRoute: ActivatedRoute, private podService: PodService) {
    super();
    
  }

  ngOnInit(): void {
    this.subscription.add(this.podService.onSelectedPodChanged.subscribe(data => {
      if(data){
        this.noPod = false;
        this.arrivedPod = data;
        if(this.isPdf(data)) this.isLoadingPdfPOD = true;
        else this.isLoadingPdfPOD = false;
      }else{
       this.noPod = true;
       this.arrivedPod = null;
       this.presentPod = null;
       this.isLoading = false
      }
     }));
     this.subscription.add( this.podService.loading.subscribe(data => {
      this.isLoading = data;
     }))
  }

  ngAfterViewChecked(){
    if((this.arrivedPod && (!this.presentPod || this.presentPod._id !== this.arrivedPod._id))){
      if(this.isPdf(this.presentPod) && !this.isPdf(this.arrivedPod)){
        setTimeout(() => {
          this.presentPod = this.arrivedPod;
          this.onFileChanged()
        },1)
      }else{
        this.presentPod = this.arrivedPod;
        this.onFileChanged()
      }
    }  
  }

  public async onFileChanged(){
    if(!this.isPdf(this.presentPod)){
      let src = await this.presentPod.localUrl();
      const imgViewer = document.getElementById('pod-type-image');
      if(!imgViewer) return;
      if(imgViewer?.firstChild){
        imgViewer.removeChild(imgViewer.firstChild);
      }
      let presentPod = document.createElement('img');
      presentPod.src = src;
      presentPod.style.display = 'none';
      presentPod.className = 'attached-pod'
      presentPod.alt = '';
      imgViewer.appendChild(presentPod);
      this.ngxViewerElement.instance.update();
      this.isLoading = false;
      return;
    }
    //pdf
    this.fileUrl = await this.presentPod.localUrl();
    this.isLoading = false;
  }

  public onBtnNext(){
    this.podService.handleChangePod('next')
  }

  public onBtnBack(){
    this.podService.handleChangePod('back');
  }

  public isPdf(item: AttachedFile): boolean {
    return AttachedFileUtil.isPdf(item);
  }

  loadedPdfPod(pdf: PDFDocumentProxy){
    this.isLoadingPdfPOD = false;
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
