import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";

@Component({
    selector: '[check-shipment-for-pod]',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
  })
export class CheckShipmentForPod extends BaseComponent{
    @Input() podId;
    public shipmentIds;
    public shipments;
    public displayShipmentIds;
    public isLoading = true;
    public isSaving = false;
    constructor(){
        super();
    }

    ngOnChanges(): void {
        if(this.podId){
            this.isLoading = true;
            const url = Const.APIV2(`${Const.APIURI_POD_CONFIRMATION}/${this.podId}/shipments`);
            this.api.GET(url).subscribe(resp => {
                this.shipmentIds = resp.data.list_data.map(shipment => shipment.warpId);
                this.shipments = resp.data.list_data;
                this.displayShipmentIds = this.shipmentIds;
                this.isLoading = false;
            })
          }
    }

    getShipmentWarpId(item){
        return `S-${item}`
    }

    onClose(id){
        this.displayShipmentIds = this.displayShipmentIds.filter(item => (item !== id));
    }

    onDiscard(){
        this.displayShipmentIds = this.shipmentIds;
    }

    onSave(){
        let url = `${Const.APIV2(Const.APIURI_TASKS)}/deletePodForListTasksOfStop`;
        let taskIds = this.shipments.filter(shipment => (this.displayShipmentIds.includes(shipment.warpId)))
                                    .map(item => item.taskIdForChecking);
        let params = {
          taskIds: taskIds,
          "uploadPodFileId" : this.podId
        };
        this.isSaving = true;
        this.api.POST(url, params).subscribe(
            (resp) => {
              this.shipmentIds = this.displayShipmentIds;
              this.shipments = this.shipments.filter(it => (this.displayShipmentIds.includes(it.warpId)));
              this.isSaving = false;
              this.showSuccess("Your POD has been deleted successfully.");
            },
            (err) => {
                this.isSaving = false;
              this.showErr(err);
            }
          );
    }
}