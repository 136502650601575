import { BaseComponent } from '@abstract/BaseComponent';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import TaskEntity from '../../Entity/TaskEntity';
import { Const } from '@const/Const';
import { WarpId } from '@wearewarp/universal-libs';
import { Utils } from '@services/utils';

@Component({
  selector: 'shipment-warpid',
  templateUrl: './shipment-warpid.html',
  styleUrls: ['./shipment-warpid.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentWarpidComponent extends BaseComponent {
  public displayInfo: any = {};
  public isExpanded: boolean = false;
  @Input() tasks: TaskEntity[] = [];
  @Input() pods: any[] = [];
  
  getRouterLink(item) {
    item = item?.getShipment()?.toJSON()
    if (item?.shipmentTransitType == Const.ShipmentTransitType.leg) {
      if (item?.parentId) {
        // Nếu đã có parentId rồi thì cho đến đúng màn luôn
        return [Const.routeAdminOrderList, item.parentId, 'tracking-items'];
      } else {
        // Nếu chưa có parentId thì cho đi qua 1 màn trung gian để fetch dữ liệu trước rồi redirect sau
        return [Const.routeAdminOrderList, 'legs', item.id];
      }
    }
    if (item?.orderId) {
      return [Const.routeAdminOrderList, item.orderId];
    }
    return [Const.routeAdminOrderList];
  }

  public getWarpIdText(task: TaskEntity) {
    return WarpId.showShipment(task.getShipment()?.getWarpId());
  }

  public getDisplayTasks(tasks) {
    if (!Utils.isArray(tasks)) return [];
    if(this.isExpanded) return tasks;
    let data = [...tasks];
    let newArray = data.slice(0, 10);
    return newArray;
  }

  public getListIds(items: TaskEntity[]) {
    const ids = [];
    items.forEach((it, index) => {
      if (index > 9) {
        ids.push(this.getWarpIdText(it));
      }
    });
    return ids.join(', ');
  }

  public getColor(shipment) {
    const podUploadFiles = shipment.getPodUploadFiles();
    if(!podUploadFiles.length){
      return "red";
    };
    for(let item of podUploadFiles){
      const pod = this.pods.filter(it => (it._id === item))[0];
      if(!this.isConfirmPOD(pod)) return "yellow";
    }
    return "green"
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  copy() {
    let text = this.tasks.map(task => this.getWarpIdText(task)).join(', ');
    if(!text) return;
    Utils.copyTextToClipboard(text, e => {
      if (e) {
        this.showErr('Cannot copy text to clipboard');
      } else {
        this.showSuccess('ShipmentIds has already been copied to the clipboard');
      }
    })
  }
  
  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }
}
