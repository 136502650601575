import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
@Component({
    selector: '[carrier-info]',
    templateUrl: './index.html',
    styleUrls: [
     './index.scss'
    ]
})
export class CarrierInfo extends BaseComponent{
    @Input() carrierId = ''
    public url = `v1/${Const.APIURI_CARRIERS}/`;
    public carrier;
    public isLoading = true;
    constructor(){
        super();
    }

    ngOnChanges(): void {       
        let apiUrl = this.url + this.carrierId;
        this.isLoading = true;
        this.api.GET(apiUrl).subscribe(
            resp => {
                this.carrier = resp.data;
                this.isLoading = false;
            }
        )
    }
}