<div class="list-item">
  <div style="margin-bottom: 3px;">
    <div class="center-vertical font-bold">
      <span style="color: var(--dispatch-black);">{{displayInfo.codeJob}}</span>
      <span style="margin-left: 8px; font-size: 15px; color: #334155; opacity: 60%" nz-icon nzType="info-circle"
        nz-tooltip="Quick Preview Stops" nzTheme="fill">
      </span>
    </div>
  </div>
  <div class="flex">
    <div style="flex: 3; color: #1890ff; font-size: 11px;">{{displayInfo.label}}</div>
  </div>
  <div class="flex font-regular" style="margin-bottom: 5px; font-size: 11px;">
    <img src="\assets\img\dispatch-icons\route.svg" alt="" style="transform: scale(0.8);">
    <div style="margin-left: 3px;">
      <div>{{displayInfo.startLocation}} - {{displayInfo.endLocation }}</div>
       <div>{{displayInfo.countStops}}</div>
    </div>
  </div>
  <div class="flex" style="margin-bottom: 5px; font-size: 11px;" [nzTooltipTitle]="detailTimePickDrop"
    nzTooltipPlacement="bottomLeft" [nzTooltipTrigger]="'hover'" nz-tooltip [nzTooltipColor]="'#334155'">
    <div class="flex" style="align-items: center;">
      <span class="f14" nz-icon nzType="calendar" nzTheme="outline" style="margin-left: 4px;" nzIconfont="small"></span>
    </div>
    <div style="margin-left: 7px;">
      <div class="flex">
        <div >
          PU: {{displayInfo.displayPick.date}}
        </div>
        <div class="font-bold" style=" margin-left: 2px;">
          {{displayInfo.displayPick.hour}} {{displayInfo.displayPick.timezone}}
        </div>
     </div>
      <div class="flex">
        <div >
          DO: {{displayInfo.displayDrop.date}}
        </div>
        <div class="font-bold" style="margin-left: 2px;">
          {{displayInfo.displayDrop.hour}} {{displayInfo.displayDrop.timezone}}
        </div>
      </div>
    </div>
    <ng-template #detailTimePickDrop >
      <div style="font-weight: 400; font-size: 11px;">Pickup Time Window: {{displayInfo.pickWindow}}</div>
      <div style="font-weight: 400; font-size: 11px;">Delivery Time Window: {{displayInfo.dropWindow}}</div>
    </ng-template>
  </div>
  <div class="center-vertical">
    <img src="/assets/img/dispatch-icons/car.svg" alt="" style="transform: scale(0.8);">
    <div style="color: var(--dispatch-black) ;font-size: 11px; margin-left: 4px;">
      <div >{{displayInfo.carrierName}}
      </div>
      <div>
        {{displayInfo.driverName}}
      </div>
    </div>
  </div>
</div>