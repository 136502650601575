import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
@Component({
    selector: 'create-by-user',
    templateUrl: './index.html',
    styleUrls: ['./index.scss']
})
export class CreateByUser extends BaseComponent {
  public displayInfo: any = {};
  @Input() createBy: any = {};
  @Input() styleTextColor: string = '#000000';
  @Input() styleFontWeight: number = 400;

  constructor() {
    super();
  }

  ngOnChanges(): void {
    this.buildDisplayInfo();
  }

  private buildDisplayInfo() {
    if (!this.createBy  || !Utils.isObjectNotEmpty(this.createBy) || !this.createBy?.byUser) return;
    this.displayInfo = {
      createdBy: this.getCreatedBy(this.createBy),
      hyperLinkCreatedBy: this.getHyperLinkCreateBy(this.createBy),
    }
  }

  public getCreatedBy(item: any): string {
    if (item?.byDriver) {
      return `${this.getFullName(item?.byDriver)} (driver)`
    } else if (item?.byCarrier) {
      return `${item.byCarrier.basicInfo?.name} (carrier)`
    }
    return this.getFullName(item?.byUser)
  }

  public getHyperLinkCreateBy(item: any): string {
    if (item?.byDriver) {
      return `${Const.routeAdminDriverList}/${item.byDriver.id}`;
    } else if (item?.byCarrier) {
      return `${Const.routeAdminCarrierList}/${item.byCarrier.id}`;
    }
    return `${Const.routeAdminUserList}/${item.byUser.id}`;
  }
}