import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
@Component({
    selector: 'pod-confirm-filter',
    templateUrl: './index.html',
    styleUrls: ["./index.scss"]
})
export class PodConfirmationFilter extends BaseComponent{
    public searchPlaceHolder = 'Search';
    public searchKeyword;
    constructor(public activatedRoute: ActivatedRoute){
        super();
        this.searchKeyword = this.activatedRoute?.snapshot?.queryParams?.search;
    }

    public doSearch(event){
        const queryParams: any = {...this.activatedRoute?.snapshot?.queryParams};
        if(event){
            queryParams.search = event;
        }
        else delete queryParams.search
        this.routeWithQueryUrl(queryParams);
    } 
}