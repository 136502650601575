import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PodConfirmationIndex } from '.';
import { SubWindow } from './components/sub-window';

const PodConfirmationRoutes: Routes = [
  {
    path: '', component: PodConfirmationIndex, data: { title: 'POD Confirmation - WARP Admin' },
  },
  { path: 'sub-window', component: SubWindow, data: { title: 'POD Confirmation - WARP Admin'}}
];
@NgModule({
  imports: [RouterModule.forChild(PodConfirmationRoutes)],
  exports: [RouterModule]
})
export class PodConfirmationRoutingModule { }
