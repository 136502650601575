import { Const } from "@const/Const";
import { Shipment } from "@wearewarp/types/data-model";
import { PodService } from "../pod.service";
import { getInjector } from "@services/injector";

export default class ShipmentEntity {
  private data: Shipment;
  constructor(private podService: PodService) {}

  init(shipment: Shipment) {
    this.data = shipment;
    return this;
  }

  getId() {
    return this.data.id;
  }

  getWarpId() {
    return this.data.warpId;
  }

  getClientId() {
    return this.data.clientId;
  }

  getClient() {
    return this.data.metadata?.client;
  }

  getPickInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.TaskType.PICKUP);
  }

  getDropInfo() {
    return this.data.deliveryInfos?.find(item => item.type == Const.TaskType.DROPOFF);
  }

  getItems() {
    return this.data.itemIds.map(itemId => this.podService.getShipmentItemById(itemId))
  }

  getStatus() {
    return this.data.status || Const.OrderStatus.needCarrier;
  }

  getTrackingCode() {
    return this.data.trackingCode;
  }

  getShipmentType() {
    return this.data.shipmentType
  }

  getShipmentModeId() {
    return this.data.shipmentModeId
  }

  getEquipmentId() {
    return this.data.equipmentId
  }

  getTempRange() {
    return this.data.metadata?.tempRange
  }

  getOrderId(){
    return this.data.orderId
  }

  getDetailUrl() {
    return `${Const.routeAdminOrderList}/${this.data.orderId}`
  }

  getTags() {
    return this.data.tags || []
  }

  toJSON() {
    return {
      ...this.data
    }
  }
}