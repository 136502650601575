import { Component, HostListener} from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Utils } from "@services/utils";
import { BaseComponent } from "@abstract/BaseComponent";
import { WarpId } from '@wearewarp/universal-libs';
import { NotesComponentEventFetchDataDone, NotesComponentEventItemAdded, NotesComponentEventItemRemoved } from "@app/admin/components/notes/interface";
import to from "await-to-js";
import { PodService } from "../../pod.service";
import TaskEntity from "../../Entity/TaskEntity";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'pod-info',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class PODInfo extends BaseComponent {
  public podItem: any;
  public taskEntities: TaskEntity[];
  public warpIds: any ;
  public hasPod = false;
  public tasks;
  public selectedJobId;
  public isExpanded :boolean = false;
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(!this.podItem) return;
    if (event.ctrlKey && event.key === 'u') {
      if(!this.isConfirmPOD(this.podItem)) return;
      this.CtrlU(this.podItem);
    }
    if (event.ctrlKey && event.key === 'r') {
      this.CtrlR(this.podItem);
    }
    if (event.ctrlKey && event.key === 'y') {
      if(this.isConfirmPOD(this.podItem)) return;
      this.onBtnConfirm(this.podItem);
    }
  }
  constructor(private podService: PodService, public activatedRoute: ActivatedRoute){
    super();
    this.refreshPodChange = this.refreshPodChange.bind(this)
    this.subscription.add(this.podService.onSelectedPodChanged.subscribe(data => {
      if(data){
        this.isExpanded = false;
        this.podItem = data;
        this.taskEntities = this.podService.getTaskEntities();
        let tasks = this.taskEntities.filter(item => (item.getPodUploadFiles().includes(this.podItem._id)))
        let shipmentIds = tasks.map(item => item.getShipmentId());
        let shipmentEntites = shipmentIds.map(shipmentId => this.podService.getShipmentById(shipmentId))
        this.warpIds = shipmentEntites.map(item => item.getWarpId());
        if(this.podService.getSelectedJob().id !== this.selectedJobId) {
          this.selectedJobId = this.podService.getSelectedJob().id;
          this.fetchTasks();
        }
        this.countTaskOfPOD(this.podItem)
        this.hasPod = true;
      }else this.hasPod = false;
    }))
    this.subscription.add(this.podService.onSelectedJobChanged.subscribe(data => {
      if(data?.stopId) {
        this.fetchTasks();
        this.countTaskOfPOD(this.podItem);
      }
    }))
  }


  public fetchTasks(){
    this.api.GET(`${Const.APIURI_JOBS_V2}/${this.selectedJobId}`).subscribe(resp =>{
      this.tasks = resp.data?.tasks;
      this.countTaskOfPOD(this.podItem);
    });
  }

  private countTaskOfPOD(item) {
      const tasks = this.getTasksOfPOD(item)
      item.countTasks = tasks.length;
      item.tasks = tasks
      item.taskString = tasks.map(task => WarpId.showShipment((<any>task)?.shipment?.warpId)).join(', ');
  }

  public getTasksOfPOD(podItem) {
    const tasks = this.tasks || []
    let podExistInTasks = tasks.filter(task => {
      const podArr = task?.podArr || [];
      return podArr.find(pod => pod._id == podItem._id)
    });
    return podExistInTasks || [];
  }

  formatDate(date) {
    return DateUtil.dateToString(date, Const.FORMAT_GUI_DATETIME_SHORT);
  }

  public showShipmentWarpId(shipment: number | { warpId: number }): string {
    return WarpId.showShipment(shipment);
  }

  isConfirmPOD(item) {
    if (item.podConfirmed?.when) return true;
    return false;
  }

  onBtnConfirm = (item) => {
    let params: any = {};
    params.uploadPodFileId = item._id;
    if(item?.taskId){
      params.taskIds = [item.taskId]
    }else{
      params.taskIds = item.taskIds;
    }
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/confirmPodForListTasksOfStop`;
    item.onProgress = true;
    this.api.POST(url, params).subscribe(
      (resp) => {
        item.podConfirmed = resp.data?.podConfirmed;
        item.onProgress = false;
        this.refreshPodChange();
        this.showSuccess("Your POD has been confirm successfully.", {nzPlacement: 'bottomRight'});
      },
      (err) => {
        item.onProgress = false;
        this.showErr(err, {nzPlacement: 'bottomRight'});
      }
    );
  };

  onBtnUnConfirm = async (item) => {
    this.confirmDeletion({
      message: "Are you sure to unconfirm this POD?",
      txtBtnOk: "Unconfirm",
      fnOk:  async () => {
       this.callApiUnConfirm(item);
      }
    });
  }
  async CtrlR(item){
    this.callApiDelete(item)
  }
  async CtrlU(item){
    this.callApiUnConfirm(item)
  }
  public isHasIssue(podItem) {
    return podItem.hasIssue && !podItem.podConfirmed?.when;
  }

  public onNoteFetchingDone(podItem, data: NotesComponentEventFetchDataDone) {
    const before = podItem.hasIssue;
    const after = data.countTotal > 0;
    if (before != after) {
      // Âm thầm correct
      podItem.hasIssue = after;
      this.correctPodIssue(podItem);
    }
  }

  private correctPodIssue(podItem) {
    const url = Const.APIV2(`pods/${podItem._id}/correct_issue_status`);
    this.api.POST(url).subscribe();
  }

  public onNoteItemAdded(podItem, data: NotesComponentEventItemAdded) {
    if (!podItem.hasIssue) {
      podItem.hasIssue = true;
    }
  }

  public onNoteItemRemoved(podItem, data: NotesComponentEventItemRemoved) {
    if (data.countBeforeRemove <= 1 && podItem.hasIssue) {
      podItem.hasIssue = false;
    }
  }
  public async refreshPodChange(){
    await this.podService.setSelectedJob(this.selectedJobId,this.podService.getSelectedStop().getId(), this.podItem?.taskId, this.podItem._id)
    let querryparams = {...this.activatedRoute?.snapshot?.queryParams}
    querryparams.jobId = this.podService.getSelectedJob()?.id;
    this.routeWithQueryUrl(querryparams);
  }

  public getDisplayWarpIds(warpIds) {
    if (!Utils.isArray(warpIds)) return [];
    if(this.isExpanded) return warpIds;
    let data = [...warpIds];
    let newArray = data.slice(0, 1);
    return newArray;
  }

  public getListIds(warpIds) {
    const ids = [];
    warpIds.forEach((it, index) => {
      if (index >= 1) {
        ids.push(this.showShipmentWarpId(it));
      }
    });
    return ids.join(', ');
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
  onBtnDelete = (item) => {
    this.confirmDeletion({
      message: "Are you sure you want to delete this POD?",
      txtBtnOk: this.txtDelete,
      fnOk: () => {
        this.callApiDelete(item);
      },
    });

  }
  
  onBtnDownload = (item) => {
    return this.downloadAttachedFile(item);
  }
  async callApiUnConfirm(item){
    let params = { "id": item._id };
    let url = `${Const.APIURI_TASKS}/${item?.taskId ? item.taskId: item.taskIds[0]}/un-confirm-pod`;
    item.onProgress = true;
    let [err] = await to(this.api.POST(url, params).toPromise());
    if (err) {
      this.showErr(err, {nzPlacement: 'bottomRight'});
    } else {
      item.podConfirmed = undefined;
    }
    item.onProgress = false;
    this.refreshPodChange();
  }

  async callApiDelete(item){
    let params: any = {};
    params.uploadPodFileId = item._id;
    if(item?.taskId){
      params.taskIds = [item.taskId]
    }else{
      params.taskIds = item.taskIds;
    }
    let url = `${Const.APIV2(Const.APIURI_TASKS)}/deletePodForListTasksOfStop`;
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.showSuccess("Your POD has been deleted successfully.", {nzPlacement: 'bottomRight'});
        this.refreshPodChange()
      },
      (err) => {
        this.showErr(err, {nzPlacement: 'bottomRight'});
      }
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
