<div style="margin-left: 10px;" >
  <div style="margin-bottom: 10px">
    <div>All: </div>
    <div class="pod-container">
      <div class="image" *ngFor="let item of podAllTasks; let i = index">
        <div *ngIf="!item.isPdf" class="pod-item" [ngClass]="{'selected': isAllTaskPodSelected(item), 'border-green': !isAllTaskPodSelected(item) && isConfirmPOD(item), 'border-red': !isAllTaskPodSelected(item) && !isConfirmPOD(item)}"  (click)="handleClick($event,item._id,stopId);" >
          <img-lazy  class="attached-pod" width="30px"  [src]="item.localUrl" alt=""></img-lazy>
        </div>
        <div *ngIf="item.isPdf">
          <div class="attached-pod pdf overlay-container pod-item" [ngClass]="{'selected': isAllTaskPodSelected(item), 'border-green': !isAllTaskPodSelected(item) && isConfirmPOD(item), 'border-red': !isAllTaskPodSelected(item) && !isConfirmPOD(item)}"  (click)="handleClick($event,item._id,stopId);" >
            <canvas id="all-tasks-pod-{{stopId}}-{{i}}" width="30px">
            </canvas>
          </div>
        </div>
      </div>
      <div *ngIf="!isUploading['all']" class="image upload-pod" (click)="onClickAddPod($event)"><span nz-icon nzType="plus" nzTheme="outline"></span></div>
      <div *ngIf="isUploading['all']" class="uploading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile)"
        id="uploadRoutePODInputAllTask{{stopId}}">
    </div>
  </div>
  <ng-container *ngIf="shouldShowEachTaskPod">
    <div *ngFor="let task of displayInfo;let i = index" style="margin-bottom: 10px">
      <div><span [ngStyle]="{'color': getColorForShipment(task)}">{{showShipmentWarpId(task.warpId)}}:</span> {{task.confirmed}}/{{task.pods.length}} confirmed</div>
      <div class="pod-container">
        <div class="image" *ngFor="let item of task.pods">
          <div *ngIf="!item.isPdf" class="pod-item" [ngClass]="{'selected': isSelected(item,task), 'border-green': !isSelected(item,task) && isConfirmPOD(item), 'border-red': !isSelected(item,task) && !isConfirmPOD(item)}"  (click)="handleClick($event,item._id,stopId, task.id);" >
            <img-lazy  class="attached-pod" width="30px"  [src]="item.localUrl" alt=""></img-lazy>
          </div>
          <div *ngIf="item.isPdf">
            <div class="attached-pod pdf overlay-container pod-item" [ngClass]="{'selected': isSelected(item,task), 'border-green': !isSelected(item,task) && isConfirmPOD(item), 'border-red': !isSelected(item,task) && !isConfirmPOD(item)} "  (click)="handleClick($event,item._id,stopId, task.id);" >
              <canvas id="task-{{item.taskId}}-pod-{{getIndexForPod(item.taskId, item._id)}}" width="30px">
              </canvas>
            </div>
          </div>
        </div>
        <div *ngIf="!isUploading[task.id]" class="image upload-pod" (click)="onClickAddPod($event, task.id)"><span nz-icon nzType="plus" nzTheme="outline"></span></div>
        <div *ngIf="isUploading[task.id]" class="uploading"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        <input #inputFile type="file" hidden accept=".pdf,.png,.jpg,.jpeg" (change)="onFileSelected(inputFile, task.id)"
          id="uploadRoutePODInput-{{task.id}}">
      </div>
    </div>
  </ng-container>
</div>
<!-- <div  *ngIf="isUploading"><i nz-icon nzType="loading" nzTheme="outline"></i></div> -->

