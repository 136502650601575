<div class="list-container">
  <div class="info-sticky" *ngIf="!hasPod">
    <div  class="nodata"><i nz-icon nzType="search"
      nzTheme="outline"></i>No data</div>
  </div>
  <div class="info-absolute">
    <div class="info-sticky" *ngIf="hasPod">
      <div class="info">
        <ng-container [ngTemplateOutlet]="podInfo" [ngTemplateOutletContext]="{podItem, warpIds}"></ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #podInfo let-podItem="podItem"  let-warpIds="warpIds">
  <div class="info">
    <div class="info-text" *ngIf="warpIds.length">
      <div class="name" style="display: flex;"><b style="margin-right: 5px;">WARP ID:</b> 
        <div *ngIf="!isExpanded">
          <div  *ngFor="let warpId of getDisplayWarpIds(warpIds); let index=index" >{{ showShipmentWarpId(warpId) }}</div>
          <ng-container *ngIf="warpIds?.length > 1">
            <a [nzTooltipTitle]="getListIds(warpIds)" nzTooltipPlacement="top" nz-tooltip nz-typography nzEllipsis (click)="toggleExpand()">
              (+{{ warpIds.length - 1 }})
            </a>
          </ng-container>
        </div>
        <div *ngIf="isExpanded">
          <div  *ngFor="let warpId of getDisplayWarpIds(warpIds); let index=index" >{{ showShipmentWarpId(warpId) }}</div>
          <a (click)="toggleExpand()">Hide</a>
        </div>
      </div>
    </div>
    <div class="appled-tasks" [nz-popover] nzPopoverTrigger="click" nzPopoverTitle="Tasks" [nzPopoverContent]="contentTemplate"
    nzPopoverPlacement="left">
      Applied for {{warpIds.length}} tasks <span nz-icon nzType="edit" nzTheme="outline"></span>
      <ng-template #contentTemplate>
        <route-update-pod-tasks [tasks]="tasks || []" [podItem]="podItem" [refreshData]="refreshPodChange"></route-update-pod-tasks>
      </ng-template>
    </div>
    <div class="info-text" style="margin-top: 10px;">     
        <div class="name">Upload by <create-by-user [createBy]="podItem?.insert" [styleFontWeight]="500"></create-by-user></div>
        <div class="date">{{formatDate(podItem.insert?.when)}}</div>
    </div>
    <div class="type">
      <div>
        <nz-tag
          [nzColor]="podItem.podType === 'Photo' || !podItem.podType ? 'blue' : podItem.podType === 'BOL' ? 'orange' : 'default'">{{podItem.podType
          || "Photo"}}
        </nz-tag>
      </div>
      <nz-space>
        <button  *nzSpaceItem nz-button nzType="default" (click)="onBtnDownload(podItem) "
          [nzLoading]="podItem?.isDownloading" style="border: none; background: transparent; padding: 4px;">
          <img src="/assets/img/dispatch-icons/sim_card_download.svg" />
        </button>
        <button *nzSpaceItem nz-button nzType="default" (click)="onBtnDelete(podItem)" style="border: none; background: transparent; padding: 4px;">
          <img src="/assets/img/dispatch-icons/delete.svg" />
        </button>
      </nz-space>
    </div>
    <div class="action">
      <div class="confirm">
        <button nz-button nzType="primary" [nzLoading]="podItem.onProgress"
          (click)="onBtnConfirm(podItem)" *ngIf="!isConfirmPOD(podItem)">Confirm</button>
        <div class="confirmed" *ngIf="isConfirmPOD(podItem)">
          <nz-tag nzColor="success">
            <i nz-icon nzType="check-circle" nzTheme="outline"></i>
            <span class="text">Confirmed</span>
          </nz-tag>
          <button nz-button nzType="dashed" nzDanger (click)="onBtnUnConfirm(podItem)" nzSize="small" nz-tooltip nzTooltipTitle="Un-confirm">
            <span nz-icon nzType="rollback" nzTheme="outline"></span>
          </button>
          <div>Confirmed by <create-by-user [createBy]="podItem?.podConfirmed" [styleTextColor]="'#3f9714'"></create-by-user></div>
          <div class="text">
            {{formatDate(podItem.podConfirmed.when)}}
          </div>
        </div>
      </div>
      <div *ngIf="isHasIssue(podItem)" class="hasIssue">
        <i nz-icon nzType="warning" nzSize="large" nzTheme="outline" class="warning"></i>
      </div>
    </div>
  </div>
</ng-template>
