<div class="list-container" scrollTracker [isScrollTop]="isScrollTop" (scrollingFinished)="getDataMore()" #target style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06)">
  <nz-table #nzTable nzBordered="true" [nzData]="listData" nzSize="small" [nzFrontPagination]="false"
    [nzLoading]="isLoading && listData?.length == 0" [nzLoadingIndicator]="tplLoading" [nzNoResult]="tplNoData"
    [nzSize]="'small'">
    <ng-template #tplNoData>
      <div *ngIf="!isLoading && !listData?.length" class="nodata"><i nz-icon nzType="search"
          nzTheme="outline"></i>No data</div>
    </ng-template>
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <tbody >
      <tr *ngFor="let item of listData; let i = index">
        <a [routerLink]="[routeAdminPODNeedConfirm]" [queryParams]="queryParams(item.id)" (click) = setSelectedItem(item.id)>
          <div job-item [job] = "item" ngClass="navigation-item {{ selectedItem === item.id ? 'selected' : '' }}"></div>
        </a>
      </tr>
      <ng-container *ngIf="isLoading && listData?.length">
        <tr>
          <td>
            <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="listData?.length >= paginationInfo?.total && paginationInfo?.total > 0">
        <tr>
          <td>
            <div style="display: flex;justify-content: center; margin-top: 10px;">
              <img class="img-notify-dispatch" src="assets/img/notifi-dispatch.png" width="50px" alt="">
            </div>

            <div style="text-align: center; margin-top: 10px;font-size: 13px;font-weight: 300;">
              Total: {{paginationInfo?.total}} loads.
              <br />That's all your loads based on filters.
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>
