<div class="row">
  <div class="stop-num flex">
    <div>
      {{displayInfo.shortType}} - Stop {{displayInfo.index}}
      <span class="stop-status {{displayInfo.status}} ">{{displayInfo.status}}</span>
      <span nz-icon style="font-size: 17px; margin-left: 10px;" nzType="search" nzTheme="outline" nz-popover nzPopoverTitle="Stop Detail Info"
      [nzPopoverContent]="StopInfo"></span>
    </div>
  </div>
</div>
<div class="row">
  <div class="stop-address" style="font-size: 11px;">
    <display-address [deliveryInfo]="displayInfo.deliveryInfo" [type]="displayInfo.type"></display-address>
  </div>
</div>
<div class="row">
  <div class="warp-info" style="font-size: 11px;">
    <span class="warp-label">Ref No:</span>
    <span *ngIf="!displayInfo.refNums">N/A</span>
    <span *ngIf="displayInfo.refNums">
      <ng-container *ngIf="displayInfo.isShowRefNumsFull">
        {{ displayInfo.refNums }}
      </ng-container>
      <ng-container *ngIf="!displayInfo.isShowRefNumsFull">
        {{ displayInfo.refNums_Short }}
        <span class="pointer" nz-popover nzPopoverTitle="All Reference Numbers" [nzPopoverContent]="displayInfo.refNums">(+{{displayInfo.refNums_More}})</span>
      </ng-container>
    </span>
  </div>
</div>
<div class="row">
  <div class="warp-info">
    <span class="warp-label" style="font-size: 11px;">Shipment IDs: </span>
    <shipment-warpid [tasks]="displayInfo?.tasks" [pods]="displayInfo?.pods"></shipment-warpid>
  </div>
</div>
<div >
  <div ><span class="warp-label" style="font-size: 11px;"><b>POD:</b> </span></div>
  <div class="row">
    <pod-list [pods]="displayInfo.pods" [tasks]="displayInfo?.tasks" [stopId]="stop.getId()"></pod-list>
  </div>
</div>
<ng-template #StopInfo>
  <div class="row">
    <div class="appoiment-info" style="font-size: 11px;">
      <display-appointment [label]="displayInfo.isScheduled ? 'Appointment' : 'Scheduled'"
        [appointment]="displayInfo.appointment" [timezone]="displayInfo.timezone"
        [scheduled]="displayInfo.isScheduled"></display-appointment>
    </div>
    <div *ngIf="displayInfo.warningAppointment" nz-tooltip style="color: #faad14; cursor: pointer;"
      [nzTooltipTitle]="tplWarningAppointment" nzTooltipOverlayClassName="tooltip-warning-appointment"
      [nzTooltipColor]="'#334155'">
      <i nz-icon nzType="warning" nzTheme="outline"></i>
      Times are different.
      <ng-template #tplWarningAppointment>
        <div *ngFor="let item of displayInfo.warningAppointment">{{item}}</div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="isShowActualTime" class="row" style="font-size: 11px;">
    <div class="actual-info">
      <b>Actual:</b>
      <br>&emsp;Arrived: {{displayInfo.actualArrived || 'N/A'}}
      <br>&emsp;Departed: {{displayInfo.actualDeparted || 'N/A'}}
    </div>
  </div>
  <div *ngIf="displayInfo.delay" class="row">
    <div class="semibold" style="color: #faad14;">Failure Code for delay: </div>
    <div>
      {{displayInfo.delay.failureCode || 'N/A'}}
      <ng-container *ngIf="displayInfo.delay.note">
        <span nz-icon nzType="info-circle" nzTheme="outline" nz-popover [nzPopoverContent]="noteDelay"
          style="cursor: pointer;"></span>
      </ng-container>
      <ng-template #noteDelay>
        <div style="white-space:pre-wrap; max-width: 300px;">{{displayInfo.delay.note}}</div>
      </ng-template>
    </div>
  </div>
</ng-template>
