<ng-container *ngIf="!isExpanded">
  <ng-container *ngFor="let task of getDisplayTasks(tasks)">
    <a [routerLink]="getRouterLink(task)" target="_blank">
      <nz-tag [nzColor]="getColor(task)">{{ getWarpIdText(task) }}</nz-tag>
    </a>
  </ng-container>
  <ng-container *ngIf="tasks?.length > 10">
    <a [nzTooltipTitle]="getListIds(tasks)" nzTooltipPlacement="top" nz-tooltip nz-typography nzEllipsis (click)="toggleExpand()">
      (+{{ tasks?.length - 10 }})
    </a>
  </ng-container>
</ng-container>
<ng-container *ngIf="isExpanded">
  <ng-container *ngFor="let task of getDisplayTasks(tasks)">
    <a [routerLink]="getRouterLink(task)" target="_blank">
      <nz-tag [nzColor]="getColor(task)">{{ getWarpIdText(task) }}</nz-tag>
    </a>
  </ng-container>
  <a (click)="toggleExpand()">Hide</a>
</ng-container>
<a (click)="copy()"><span nz-icon nzType="copy"></span></a>