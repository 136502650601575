import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { TaskType } from "@wearewarp/types";
import StopEntity from "../../Entity/StopEntity";
import { DateUtil } from "@services/date-utils";
import { MasterData } from "@services/master.data";
import { PodService } from "../../pod.service";

@Component({
  selector: 'stop-item',
  templateUrl: './index.html',
  styleUrls: [
    './index.scss'
  ]
})
export class StopItem extends BaseComponent {
  @Input() isShowActualTime = true;
  @Input() stop: StopEntity;
  public isLoading = true;
  public showShipments = false;
  public displayInfo: any = {};
  ngOnChanges(): void {
    this.buildDisplayInfo();
  }

  constructor(
   private podService: PodService
  ){
    super();
  }

  private async buildDisplayInfo() {
    if (!this.stop) return;
    let data: any = this.stop.toJSON();
    let pods = this.podService.getPodByStop(this.stop.getId()) || [];
    const newData = {
      shortType: this.getDisplayType(this.stop.getType()),
      type: this.stop.getType(),
      index: this.stop.getIndex() + 1,
      status: this.stop.getStatus(),
      totalPod: this.stop.getTotalPod(),
      locationName: this.stop.getLocationName(),
      deliveryInfo: this.stop.getDeliveryInfo(),
      appointment: this.stop.getAppointment(),
      warningAppointment: this.stop.getWarningAppointment(),
      isScheduled: this.stop.isAppointmentSchduled(),
      timezone: this.stop.getTimezone(),
      shipmentCount: this.stop.getTasks().filter(task => task.getShipmentId()).length,
      tasks: this.stop.getTasks(),
      actualArrived: this.getDisplayTime(this.stop.getArrivedTime()),
      actualDeparted: this.getDisplayTime(this.stop.getDepartedTime()),
      refNums: this.stop.getRefNums().join(', '),
      isShowRefNumsFull: this.isShowRefNumsFull(),
      refNums_Short: this.getRefNums_Short(),
      refNums_More: this.getMoreRefNums(),
      delay: this.getDisplayDelayInfo(this.stop.getFirstTask()?.getDelay()),
      numberClient: this.stop.getShipmentClientIds()?.length,
      pods: pods || []
    }
    // if (JSON.stringify(newData) == JSON.stringify(this.displayInfo)) return;
    this.displayInfo = newData
    //chỉ chạy lần đầu để tránh giật
  }

  private getDisplayType(stopType: TaskType) {
    switch (stopType) {
      case Const.TaskType.PICKUP: return "PU"
      case Const.TaskType.RETURN: return "RE"
      default: return "DO"
    }
  }

  private getDisplayTime(time) {
    return DateUtil.displayLocalTime(time,
      {
        timezone: this.stop.getTimezone(),
        format: 'MM/DD/YY h:mm A'
      }
    )
  }

  private isShowRefNumsFull() {
    let refNums = this.stop.getRefNums() || [];
    return refNums.length <= 2;
  }

  private getRefNums_Short() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length <= 2) {
      return this.stop.getRefNums().join(', ');
    } else {
      return `${refNums[0]}, ${refNums[1]}`;
    }
  }

  private getMoreRefNums() {
    let refNums = this.stop.getRefNums() || [];
    if (refNums.length > 2) {
      return refNums.length-2;
    }
  }

  private getDisplayDelayInfo(delayInfo) {
    if (!delayInfo) return null;
    let data: any = {};
    const listDelayCodes = MasterData.getDelayCodes();
    if (delayInfo?.delayCodeId) {
      data.failureCode = listDelayCodes.find((option) => option._id == delayInfo.delayCodeId)?.name;
      data.note = delayInfo.note;
    }
    return data;
  }
}
